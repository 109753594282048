import React from 'react';
import LoginForm from 'src/components/core/AppBar/TopBar/LoginForm';

function SigninPage() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <LoginForm />
    </div>
  );
}

export default SigninPage;
