import { forwardRef, useEffect, useState } from 'react';
import { Snackbar, Typography } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import {
  onMessageListener,
  askForPermissioToReceiveNotifications,
} from 'src/firebase';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notification() {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [tokenInfo, setTokenInfo] = useState();

  const [notificationContent, setNotificationContent] = useState();
  const { userInfo } = useSelector((state) => state.authReducer);
  const { t } = useTranslation();

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationOpen(false);
    setTokenInfo();
  };

  const unsubscribe = onMessageListener()
    .then((payload) => {
      setNotificationContent({
        title: payload?.notification.title,
        body: payload?.notification.body,
      });
      setNotificationOpen(true);
    })
    .catch((err) => console.log(err));

  useEffect(() => {
    if (userInfo) {
      askForPermissioToReceiveNotifications({
        userId: userInfo.id,
        setTokenInfo,
      });

      return () =>
        unsubscribe.catch((err) =>
          console.log('unsubscribing request failed: ', err)
        );
    }
  }, []);

  useEffect(() => {
    if (tokenInfo?.isFound || tokenInfo?.error) {
      setNotificationOpen(true);
    }
  }, [tokenInfo?.isFound || tokenInfo?.error]);

  return (
    <div>
      {notificationContent?.body && (
        <Snackbar
          open={notificationOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={
              tokenInfo?.isFound || notificationContent?.body
                ? 'success'
                : 'error'
            }
            sx={{ width: '100%' }}
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ marginBottom: 2 }}
            >
              {notificationContent?.title}
            </Typography>
            {notificationContent?.body && (
              <Typography variant="body2">
                {notificationContent?.body}
                {/* {notificationContent?.body
              ? notificationContent?.body
              : tokenInfo?.isFound
              ? t('pushNotificationTokenCreatedSuccessfully')
              : t(tokenInfo?.error)} */}
              </Typography>
            )}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default Notification;
