import { Box, Divider, Drawer, List, makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCartItemsAction } from 'src/store/actions/cart.actions';
import { getNavCatsAction } from 'src/store/actions/page.action';

import { SET_USER_UI_SETTINGS } from 'src/store/constants/app.settings.types';
import { SET_AUTH_DIALOG } from 'src/store/constants/auth.types';
import NavItem from './NavItem';
import DrawerContent from './MobileDrawer/DrawerContent';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 50,
    width: document.innerWidth,
    width: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: 0,
    borderRight: 0,
    zIndex: 100,
    background: '#fff',
    // position: 'relative',
  },
  navContainer: {
    display: 'flex',
    height: 45,
    [theme.breakpoints.down('xs')]: {
      height: 45,
    },
  },
  badge: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  navItemSeparator: {
    height: 15,
    width: 1,
    background: theme.palette.primary.main,
    margin: '0 6px',
  },
  btn: {
    padding: 0,
    borderRadius: 0,
    fontSize: 13,
    '&:hover': {
      background: theme.palette.primary.main,
      color: 'white',
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
  },
  searchWrapper: {
    with: 260,
    height: 35,
    marginBottom: 10,
  },
  searchInput: {
    height: 35,
    borderRadius: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    borderTop: 0,
    padding: 12,
    width: 223,
    outline: 0,
  },
  searchIcon: {
    background: theme.palette.primary.main,
    width: 37,
    height: 35,
  },

  mobileDrawer: {
    width: '80%',
  },
}));

function MobileDrawer() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { navCats, navItems } = useSelector((state) => state.pageReducer);
  const { cartItems, wishlistProducts, notifymeProducts } = useSelector(
    (state) => state.cartReducer
  );
  const {
    userUISettings: { isMobileNavOpen, showSearchInput },
  } = useSelector((state) => state.appSettingsReducer);
  // const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const classes = useStyles({ dir: i18n.dir() });

  const { userInfo, authDialogOpen } = useSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    userInfo && dispatch({ type: SET_AUTH_DIALOG, payload: false });
  }, [userInfo]);

  useEffect(() => {
    !navCats && dispatch(getNavCatsAction());
  }, []);

  useEffect(() => {
    !cartItems.length && dispatch(getCartItemsAction('cart'));
    !wishlistProducts && dispatch(getCartItemsAction('favorite'));
    !notifymeProducts && dispatch(getCartItemsAction('notify_me'));
  }, []);

  const mobileNavbar = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <List>
          {navItems?.map((item, idx) => (
            <div key={Math.random().toString()}>
              <NavItem item={item} />
              {idx + 1 !== navItems.length && <Divider />}
            </div>
          ))}
        </List>
      </Box>
    </Box>
  );

  useEffect(() => {
    // setIsMobileNavOpen(false);
    dispatch({
      type: SET_USER_UI_SETTINGS,
      payload: { isMobileNavOpen: false },
    });
  }, [history.location.pathname]);

  return (
    <Drawer
      anchor={i18n.dir() === 'ltr' ? 'left' : 'right'}
      classes={{ paper: classes.mobileDrawer }}
      // onClose={() => setIsMobileNavOpen(false)}
      onClose={() =>
        dispatch({
          type: SET_USER_UI_SETTINGS,
          payload: { isMobileNavOpen: false },
        })
      }
      // open={true}
      open={isMobileNavOpen}
      variant="temporary"
    >
      <DrawerContent />
      {/* {mobileNavbar} */}
    </Drawer>
  );
}

export default MobileDrawer;
