import React, { useState, useEffect } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home'; // Placeholder for custom Home icon
import StorefrontIcon from '@material-ui/icons/Storefront';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ShoppingBagIcon from '@material-ui/icons/ShoppingBasket';
// import { useHistory } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';

const CustomHomeIcon = (props) => (
  <SvgIcon {...props}>
    {/* Replace with your SVG path */}
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
  </SvgIcon>
);

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main, // Dark blue background
    position: 'fixed',
    height: '100%',
    bottom: 0,
    width: '100%',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height: 80, // Height of the bottom navigation
    zIndex: 1000, // Ensure the nav is above other elements
  },
  action: {
    color: 'white',
  },
  selected: {
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      '&.MuiBottomNavigationAction-label': {
        color: theme.palette.secondary.main,
      },
      '&.MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
      },
    },
  },
}));
function MobileBottomNav() {
  const [selectedTab, setSelectedTab] = useState(0); // 0 = Home tab active by default
  const classes = useStyles();
  // const { push } = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // set selectedTab to the current route
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case '/':
        setSelectedTab(0);
        break;
      case '/categories/book_pharmacy':
        setSelectedTab(1);
        break;
      case '/categories/celebrities':
        setSelectedTab(2);
        break;
      case '/categories/library':
        setSelectedTab(3);
        break;
      case '/categories/store':
        setSelectedTab(4);
        break;
      default:
        setSelectedTab(0);
    }
  }, []);

  return (
    <BottomNavigation
      value={selectedTab}
      onChange={(event, newValue) => setSelectedTab(newValue)}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Home"
        href="/"
        icon={<HomeIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Pharmacy"
        href="/categories/book_pharmacy"
        icon={<StorefrontIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Celebrities"
        href="/categories/celebrities"
        icon={<FingerprintIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Library"
        href="/categories/library"
        icon={<LibraryBooksIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Store"
        href="/categories/store"
        icon={<ShoppingBagIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
    </BottomNavigation>
  );
}

export default MobileBottomNav;
