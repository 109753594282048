import {
  GET_APP_SETTINGS_FAIL,
  GET_APP_SETTINGS_REQUEST,
  GET_APP_SETTINGS_SUCCESS,
  GET_REFUNDS_POLICY_FAIL,
  GET_REFUNDS_POLICY_REQUEST,
  GET_REFUNDS_POLICY_SUCCESS,
  GET_RETURNS_POLICY_FAIL,
  GET_RETURNS_POLICY_REQUEST,
  GET_RETURNS_POLICY_SUCCESS,
  SET_USER_UI_SETTINGS,
  SET_IS_PHYSICAL_DEVICE,
} from '../constants/app.settings.types';

const initialState = {
  loading: false,
  error: null,
  appSettings: null,
  isMobileDevice: false,
  userUISettings: {
    isMobileNavOpen: false,
    showSearchInput: false,
    snackOpen: false,
    mobileOS: 'unknown',
  },
};

const appSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    // get returns policy
    case GET_RETURNS_POLICY_REQUEST:
      return { ...state, error: null, loading: true };

    case GET_RETURNS_POLICY_SUCCESS:
      return {
        ...state,
        returnsPolicy: action.payload,
        error: null,
        loading: false,
      };

    case GET_RETURNS_POLICY_FAIL:
      return { ...state, error: action.error, loading: false };

    // get refunds policy
    case GET_REFUNDS_POLICY_REQUEST:
      return { ...state, error: null, loading: true };

    case GET_REFUNDS_POLICY_SUCCESS:
      return {
        ...state,
        refundsPolicy: action.payload,
        error: null,
        loading: false,
      };

    case GET_REFUNDS_POLICY_FAIL:
      return { ...state, error: action.error, loading: false };

    // get app settings
    case GET_APP_SETTINGS_REQUEST:
      return { ...state, error: null, loading: true };

    case GET_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        appSettings: action.payload,

        refundsPolicy: action.payload.find((e) => e.key == 'refunds_policy'),
        returnsPolicy: action.payload.find((e) => e.key == 'return_policy'),
        arRefundsPolicy: action.payload.find(
          (e) => e.key == 'refunds_policy_ar'
        ),
        arReturnsPolicy: action.payload.find(
          (e) => e.key == 'return_policy_ar'
        ),
        error: null,
        loading: false,
      };

    case GET_APP_SETTINGS_FAIL:
      return { ...state, error: action.error, loading: false };

    case SET_USER_UI_SETTINGS:
      return {
        ...state,
        userUISettings: { ...state.userUISettings, ...action.payload },
      };

    case SET_IS_PHYSICAL_DEVICE:
      console.log('reducer', action.payload);
      return {
        ...state,
        isMobileDevice: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default appSettingsReducer;
