import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Button,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormikButton from 'src/components/FormUI/FormikButton';
import FormikInput from 'src/components/FormUI/FormikInput';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Center from 'src/components/Center';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from 'src/store/actions/auth.actions';
import SocialLogin from './SocialLogin';

const initialValues = {
  email: '',
  password: '',
};
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Enter valid email').required('Required'),
  password: Yup.string().required('Required'),
});

const platforms = [
  { icon: 'Icons-25' },
  { icon: 'Icons-26' },
  { icon: 'Icons-27' },
  // { icon: 'Icons-28' },
];

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    // padding: '0 15px 20px 15px',
    padding: '0 10px',
    maxWidth: 350,
    // width: 350,
  },
  btnStyle: {
    marginTop: 10,
  },
  separator: {
    width: 10,
  },
  rememberMe: {
    fontFamily: 'sfUiDisplayRegular',
    color: theme.custom.myTheme.grey3,
  },
}));

const LoginForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { loading, error } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const onSubmit = (values, props) => {
    dispatch(loginAction(values));
    // alert(JSON.stringify(values), null, 2);
    props.resetForm();
  };

  return (
    <Grid>
      <Paper elevation={0} className={classes.paperStyle}>
        <Typography
          color="primary"
          style={{
            fontFamily: 'sfUiTextRegular',
            fontSize: window.innerWidth > 600 ? 25 : 18,
          }}
        >
          {t('welcomeBack')}! <br /> {t('signInNow')}
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormikInput
                  name="email"
                  placeholder={t('email')}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  name="password"
                  placeholder={t('password')}
                  // type="password"
                  type={showPassword ? 'text' : 'password'}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((state) => !state)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={() => setRememberMe((state) => !state)}
                  name="rememberMe"
                  color="primary"
                />
              }
              className={classes.rememberMe}
              label={t('rememberMe')}
            />
            {error && (
              <Center>
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              </Center>
            )}

            <Center style={{ flexDirection: 'column' }}>
              <Center style={{ flexDirection: 'row', margin: '20px 0' }}>
                <Typography
                  color="primary"
                  style={{
                    fontFamily: 'sfUiDisplayRegular',
                    // fontSize: 16,
                    fontSize: window.innerWidth > 400 ? 16 : 12,
                  }}
                >
                  {t('forgotPassword')}
                </Typography>
                <Button href="/forgot-password">
                  <Typography
                    variant="button"
                    color="secondary"
                    style={{
                      fontFamily: 'sfUiDisplayBold',
                      fontSize: window.innerWidth > 400 ? 16 : 12,
                    }}
                  >
                    {t('resetNow')}
                  </Typography>
                </Button>
              </Center>

              <FormikButton
                disabled={loading}
                text={t('login')}
                style={{ width: '100%', marginBottom: 20 }}
              />

              <Typography
                color="primary"
                style={{ fontFamily: 'sfUiDisplayRegular' }}
              >
                {t('orSigninUsing')}
              </Typography>

              <Center style={{ margin: '20px 0' }}>
                <Center>
                  {/* {platforms.map((platform, idx) => (
                    <IconButton key={Math.random().toString()} size="small">
                      <img
                        style={{
                          width: 50,
                          height: 50,
                          objectFit: 'contain',
                        }}
                        src={`/images/icons/${platform.icon}.png`}
                        alt={platform.icon}
                      />
                    </IconButton>
                  ))} */}
                  <SocialLogin />
                </Center>
              </Center>

              <Center style={{ flexDirection: 'row' }}>
                <Typography
                  color="primary"
                  style={{ fontFamily: 'sfUiDisplayRegular' }}
                >
                  {t('newInDiwan')}?
                </Typography>
                <Button href="/signup">
                  <Typography
                    variant="button"
                    color="secondary"
                    style={{ fontFamily: 'sfUiDisplayBold' }}
                  >
                    {t('signup')}
                  </Typography>
                </Button>
              </Center>

              <Typography
                color="primary"
                style={{
                  fontFamily: 'sfUiDisplayRegular',
                }}
              >
                {t('andBeOurFriend')}
              </Typography>
            </Center>
          </Form>
        </Formik>
      </Paper>
    </Grid>
  );
};

export default LoginForm;
