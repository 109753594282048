import axios from 'axios';
import { localStoragePrefix } from '../config';

// const baseURL = process.env.REACT_APP_BASE_URL_PROD;

// if (
//   !process.env.REACT_APP_NODE_ENV ||
//   process.env.REACT_APP_NODE_ENV === 'development'
// ) {
//   // dev code
//   baseURL = process.env.REACT_APP_BASE_URL_DEV;
// } else {
//   // production code
//   baseURL = process.env.REACT_APP_BASE_URL_PROD;
// }

const token = localStorage.getItem(localStoragePrefix + 'token');
const baseURL_DEV =
  'https://testdiwanworld.azurewebsites.net/diwanapi/api/public/diwan';
const baseUrl_PROD = 'https://diwanworld.app/diwanapi/api/public/diwan/';

const instance = axios.create({
  baseURL: baseUrl_PROD,
  // baseURL: 'https://dardiwan.azurewebsites.net/diwanapi/api/public/diwan/',

  headers: {
    Authorization: token ? `Bearer ${token}` : '',
  },
});

export default instance;
