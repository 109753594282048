import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  useTheme,
  Button,
} from '@material-ui/core';
import {
  ArrowBack,
  Fingerprint,
  LocalPharmacy,
  LibraryBooks,
  Create as Feather,
  Store,
  Loyalty as Tag,
  AccountCircle,
  PhoneInTalk,
  Language,
  Facebook,
  Twitter,
  Instagram,
  WhatsApp,
  Menu,
} from '@material-ui/icons';
// import { ThemeContext } from 'src/context/ThemeContext'; // Adjust path as per your project
import {
  setUserLocalInfo,
  signoutAction,
} from 'src/store/actions/auth.actions'; // Adjust path as per your project
import { useHistory } from 'react-router-dom';

// Social media links
const socials = [
  { id: '1', icon: Facebook, link: 'https://www.facebook.com/DiwanWorld' },
  { id: '2', icon: Twitter, link: 'https://twitter.com/diwanworld' },
  { id: '3', icon: Instagram, link: 'https://www.instagram.com/diwanworld/' },
  {
    id: '4',
    icon: WhatsApp,
    link: 'https://api.whatsapp.com/send?phone=96591111474',
  },
];

// Drawer routes configuration
const drawerRoutes = [
  {
    name: 'diwan',
    icon: 'diwan',
    image: '/images/Diwan World App - Logo.svg',
    auth: false,
  },
  {
    name: 'celebrities',
    icon: Fingerprint,
    route: '/categories/celebrities',
    auth: false,
  },
  {
    name: 'pharmacy',
    icon: LocalPharmacy,
    route: '/categories/book_pharmacy',
    auth: false,
  },
  {
    name: 'library',
    icon: LibraryBooks,
    route: '/categories/library',
    auth: false,
  },
  { name: 'authors', icon: Feather, route: '/authors', auth: false },
  { name: 'store', icon: Store, route: '/store', auth: false },
  { name: 'brands', icon: Tag, route: '/brands', auth: false },
  { name: 'myAccount', icon: AccountCircle, route: '/profile', auth: true },
  {
    name: 'contactUs',
    icon: PhoneInTalk,
    route: '/profile/5',
    auth: false,
  },
];

// Define styles using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 74, 135, 0.93)',
    color: 'white',
    height: '100%',
  },
  listItem: {
    color: 'white',
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: 30,
  },
  socialIcon: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  logo: {
    width: 28,
    height: 28,
    objectFit: 'contain',
  },
}));

export default function DrawerContent({ open, onClose }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  // const { colors } = useContext(ThemeContext);
  const [user, setUser] = useState(null);
  const { userInfo } = useSelector((state) => state.authReducer);

  // Fetch user info from localStorage on mount
  // useEffect(() => {
  //   const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  //   setUser(userInfo ? 1 : 2);
  // }, []);

  const theme = useTheme();

  // Function to switch language and handle RTL/LTR
  const switchLanguage = async (lang) => {
    // try {
    //   localStorage.setItem('langInfo', lang);
    //   dispatch(setUserLocalInfo('lang', lang));
    //   await i18n.changeLanguage(lang);
    //   const isRTL = lang === 'ar';
    //   document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
    // } catch (error) {
    //   console.error('Error switching language:', error);
    // }
  };

  return (
    <div className={classes.headerRoot}>
      {/* Header Section */}
      <div>
        <IconButton onClick={onClose}>
          <ArrowBack style={{ color: 'white' }} />
        </IconButton>
        <Button
          onClick={() => {
            if (!userInfo) {
              push('/signin');
            } else {
              dispatch(signoutAction());
            }
          }}
          style={{ backgroundColor: theme.palette.secondary.main }}
        >
          <Typography
            variant="button"
            style={{ color: 'white', textTransform: 'uppercase' }}
          >
            {t(!userInfo ? 'signinSignup' : 'signout')}
          </Typography>
        </Button>
      </div>
      {/* <Divider /> */}

      {/* Navigation List */}
      <List style={{ border: 'none', marginTop: 20 }}>
        {drawerRoutes.map(
          ({ name, icon: Icon, route, image, auth }) =>
            ((auth && userInfo) || !auth) && (
              <ListItem
                button
                key={name}
                onClick={() => {
                  push(route);
                  // if (parent) {
                  //   push(`/${parent.toLowerCase()}/${route || name}`);
                  // } else {
                  //   push(`/${route || name}`);
                  // }
                  // onClose();
                }}
                className={classes.listItem}
              >
                <ListItemIcon>
                  {image ? (
                    <img
                      className={classes.logo}
                      src="/svg/top-logo.svg"
                      // src="/logo/logo.png"
                      alt="Diwan"
                    />
                  ) : (
                    <Icon style={{ color: 'white' }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  style={{ textAlign: 'start' }}
                  primary={t(name)}
                />
              </ListItem>
            )
        )}

        {/* Language Switch */}
        <ListItem
          button
          onClick={() => switchLanguage(i18n.language === 'ar' ? 'en' : 'ar')}
          className={classes.listItem}
        >
          <ListItemIcon>
            <Language style={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText
            primary={i18n.language === 'ar' ? 'English' : 'العربية'}
          />
        </ListItem>
      </List>
      {/* <Divider /> */}

      {/* Social Media Links */}
      <div className={classes.socialIcons}>
        <Typography
          variant="body1"
          style={{ color: 'white', marginBottom: 10 }}
        >
          {t('followUs')}
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {socials.map((social) => (
            <IconButton
              key={social.id}
              onClick={() => window.open(social.link, '_blank')}
              className={classes.socialIcon}
            >
              <social.icon />
            </IconButton>
          ))}
        </div>
      </div>
    </div>
  );
}
